import React, { useEffect } from "react"

import "aos/dist/aos.css"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/seo"

import ListenerUspMedia from "../components/listenerUspMedia"
import NotFoundPage from "./404"

import GetWebpFormat from "../utils/getWebpFormat"
import { sendAmplitudeData, initAmplitude } from "../utils/amplitude"
import { AmplitudeEvents } from "../types/amplitudeEvents"

const IndexPage = context => {
  const landingPageConfigs = context.pageContext.node
  const headerData = context.pageContext.headerConfigurations
  const footerData = context.pageContext.footerConfigurations

  useEffect(() => {
    window.scrollTo(0, 0)

    initAmplitude()
    const extraParams = {}
    if (document && document.referrer) {
      extraParams["ref-url"] = document.referrer
    }
    sendAmplitudeData(AmplitudeEvents.MARKETING_PAGE_VISITED, extraParams)

    const AOS = require("aos")
    AOS.init({
      duration: 1800,
      once: true,
    })

    if (AOS) {
      AOS.refresh()
    }

    if (document.querySelector(".js-videoPoster")) {
      document.querySelector(".js-videoPoster").onclick = event => {
        event.preventDefault()

        const $currentElement = event.target.closest(".js-videoPoster")
        videoPlay($currentElement.closest(".js-videoWrapper"))
      }
    }
  }, [])

  const onClickHandler = () => {
    sendAmplitudeData(AmplitudeEvents.HOMEPAGE_CTA_CLICKED)
    window.location.href = landingPageConfigs.hero_section.cta_button.url
  }

  function videoPlay($wrapper) {
    const $iframe = $wrapper.querySelector(".js-videoIframe")

    $wrapper.classList.add("videoWrapperActive")
    $iframe.setAttribute("src", $iframe.dataset["src"])
  }

  if (!landingPageConfigs) return <NotFoundPage />

  return (
    <Layout headerData={headerData} footerData={footerData}>
      {landingPageConfigs.seo && <SEO seo={landingPageConfigs.seo} />}

      <section className="hero-section">
        <div className="content-layer">
          <h1 className="h1" data-aos="animate-reveal">
            {landingPageConfigs.hero_section.caption}
          </h1>
          <p className="h2" data-aos="animate-reveal">
            {landingPageConfigs.hero_section.body_text}
          </p>

          <div data-aos="animate-reveal" className="cta-wrapper">
            <a
              onClick={onClickHandler}
              className="cta-button"
              target={landingPageConfigs.hero_section.cta_button.target}
            >
              {landingPageConfigs.hero_section.cta_button.button_text}
            </a>
            <br />
            <span className="cta-alert">
              *works with licensed Zoom accounts only
            </span>
          </div>

          <div
            data-aos="animate-reveal"
            className="video-wrapper videoWrapper169 js-videoWrapper"
          >
            <div className="square-logo-wrapper" data-aos="animate-reveal">
              <img
                className="square-logo"
                alt="listener"
                src="https://res.cloudinary.com/listener-cms/image/upload/f_auto/v1609931412/listener-square-logo_kmwotc.svg"
              />
            </div>

            <iframe
              className="videoIframe js-videoIframe"
              title="homepage-video"
              src=""
              frameBorder="0"
              allowtransparency="true"
              autoPlay={true}
              allowFullScreen
              allow="autoplay"
              data-src={`https://www.youtube.com/embed/${landingPageConfigs.hero_section.VideoContainer.video_id}?autoplay=1&modestbranding=1&rel=0`}
            ></iframe>

            <button
              className="videoPoster js-videoPoster"
              aria-label="Video Background Image"
              style={{
                backgroundImage: `url(${GetWebpFormat(
                  landingPageConfigs.hero_section.VideoContainer
                    .cover_image_desktop.url
                )})`,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="159.933"
                height="159.933"
                viewBox="0 0 159.933 159.933"
              >
                <g transform="translate(-520 -258)">
                  <circle
                    opacity="0.6"
                    cx="79.967"
                    cy="79.967"
                    r="79.967"
                    transform="translate(520 258)"
                  />
                  <path
                    fill="#fff"
                    d="M111.652,76.751,63.974,50.162a2.086,2.086,0,0,0-3.1,1.821v53.849a2.086,2.086,0,0,0,3.1,1.821L111.65,81.12a2.5,2.5,0,0,0,0-4.369"
                    transform="translate(519 259.084)"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>

        <div className="background-layer" role="presentation">
          <div
            data-aos="animate-yellow-triangle"
            className="yellow-triangle"
          ></div>
          <div
            data-aos="animate-muti-triangle-artwork"
            className="abstract-art"
          ></div>
          <div
            data-aos="animate-triangle-pattern-sand"
            className="triangle-pattern-sand"
          ></div>
          <div
            data-aos="animate-triangle-arrow-pattern-blue"
            className="triangle-pattern-aqua"
          ></div>
        </div>
      </section>

      <section className="features-section">
        <h2 className="features-section-heading">
          {landingPageConfigs.usps_section.section_heading}
        </h2>

        <ul className="feature-list">
          {landingPageConfigs.usps_section.listener_usps.map(
            (listenerUspSection, index) => {
              return (
                <li key={index}>
                  <div className="feature-wrapper">
                    <div className="feature">
                      <div className="feature-content">
                        <h4>{listenerUspSection.heading}</h4>
                        <p>{listenerUspSection.description}</p>
                      </div>

                      {listenerUspSection.media &&
                        ListenerUspMedia(
                          listenerUspSection.media.url,
                          listenerUspSection.heading
                        )}

                      <div className="feature-background-wrapper">
                        <div className="feature-background">
                          <span className="sand"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            }
          )}
        </ul>
      </section>

      <section className="benefits-section">
        <div className="content-layer">
          <h3 className="benefits-section-heading" data-aos="animate-reveal">
            {landingPageConfigs.benefits_section.section_heading}
          </h3>
          <img
            data-aos="animate-reveal"
            className="listener-arrows"
            alt="listener-arrows"
            src="https://res.cloudinary.com/listener-cms/image/upload/f_auto/v1608715486/listerner-icon-arrows_xarkwv.svg"
          />

          <div data-aos="animate-reveal">
            {landingPageConfigs.benefits_section.benefits.map(
              (benefit, index) => {
                return (
                  <div
                    className={`content-block content-block-${index}`}
                    key={index}
                  >
                    <h6>{benefit.heading}</h6>
                    <p>{benefit.description}</p>
                  </div>
                )
              }
            )}
          </div>
        </div>

        <div className="background-layer" role="presentation">
          <div
            data-aos="animate-benefits-abstract-art"
            data-aos-anchor=".listener-arrows"
            className="abstract-art"
          ></div>
          <div
            data-aos="animate-benefits-triangle-pattern-sand"
            data-aos-anchor=".listener-arrows"
            className="triangle-pattern-sand"
          ></div>
          <div
            data-aos="animate-benefits-triangle-pink"
            data-aos-anchor=".listener-arrows"
            className="triangle-pink"
          ></div>
          <div
            data-aos="animate-benefits-triangle-pattern-aqua"
            className="triangle-pattern-aqua"
          ></div>
        </div>
      </section>

      <section className="cta-jumbo-section for-home-page">
        <h5>{landingPageConfigs.cta_section.caption}</h5>
        <div>
          <a
            href={landingPageConfigs.cta_section.url}
            className="cta-button"
            target={landingPageConfigs.cta_section.target}
          >
            {landingPageConfigs.cta_section.button_text}
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
